import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import React from "react";
import {useNavigate} from "react-router";
import "./TransactionModal.scss"

function TransactionModal({transaction,setViewTransaction,cancelTransaction,isAdmin}) {
	const navigate = useNavigate();
	return (
		<Modal showCloseIcon closeModal={()=>{setViewTransaction(null)}} >
			<div className="modal-content primary transaction">
				<Header title="Transaccion"/>
				<div className="edit-area">
					<InputField 
						label="Identificador"
						value={transaction.uuid}
						readOnly
					/>
					<div className="split">
						<InputField 
							label="Emisor"
							value={transaction.senderId}
							readOnly
						/>
						<InputField 
							label="Receptor"
							value={transaction.receiverId}
							readOnly
						/>
					</div>

					<div className="split">
						<InputField 
							label="Cantidad"
							value={transaction.amount}
							readOnly
						/>
					</div>
					<Textarea 
						label="Descripcion"
						value={transaction.description}
						rows={15}
						readOnly
					/>
				</div>

				<div className="controls">
					{transaction.status === "pending"?<>
						
						{transaction.status === "pending" && cancelTransaction?
							<Button className="cancel" variant="secondary" action={()=>{
								cancelTransaction(transaction);
							}}>Cancelar transaccion</Button>
						:null}
						{/* TODO - workaround until we make admin endpoints */}
						{transaction.status === "pending" && !isAdmin?
							<Button className="verify" variant="primary" action={()=>{navigate("/transactions/confirm",{ 
								state:transaction
							})}}>Ir a verificion</Button>
						:null}
					</>
					:null}




					<Button variant="secondary" action={()=>{setViewTransaction(null)}} className="close">
						Cerrar
					</Button>


				</div>
			</div>
		</Modal>
	);
}

export default TransactionModal;