import {addAlert} from "Actions/SystemActions";
import {fetchLoggedUser, logout} from "Actions/UserActions";
import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import React,{useState,useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useQuery} from "Utils/hooks";
import "./VerifyAccount.scss";

function VerifyAccount() {

	const [code,setCode] = useState('');
	const [error,setError] = useState(null)
	const loggedIn = useSelector(store=>store.UserReducer.loggedIn);
	const status = useSelector(store=>store.UserReducer.data?.status);
	const validatedEmail = useSelector(store=>store.UserReducer.data?.validatedEmail);
	const validatedPhone = useSelector(store=>store.UserReducer.data?.validatedPhone);
	const [activeTab,setActiveTab] = useState("email");
	const query = useQuery();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(()=>{
		if(query.has("code")){
			setCode(query.get("code"));
		}
		if(query.has("method")){
			setActiveTab(query.get("method"));
		}
	},[])

	useEffect(()=>{	
		if(status === "active" && (validatedEmail && validatedPhone)){
			navigate("/onboarding")
		}
	},[status,validatedEmail,validatedPhone]);

	useEffect(() => {
		const newActiveTab = !validatedEmail ? "email" : !validatedPhone ? "sms" : null;
		setActiveTab(newActiveTab);
	}, [validatedEmail, validatedPhone]);

	useEffect(()=>{
		if(code.length === 8){
			axiosWithAuth().post("auth/complete_verification",{code,method:activeTab}).then(res=>{
				if(res.status === 201){
					if(loggedIn){
						navigate("/onboarding");
						dispatch(fetchLoggedUser());
						dispatch(addAlert({text:"Cuenta verificada correctamente", type:"success"}));
					}else{
						navigate("/login");
						dispatch(addAlert({text:"Cuenta verificada correctamente. Por motivos de seguridad nos gustaria que introdujeras tu contraseña", type:"success"}));
					}
				}
			}).catch(err=>{
				if(err?.response?.status === 404){
					setError("El codigo no es valido");
				}else if(err?.response?.status === 409){
					navigate("/onboarding");
					dispatch(fetchLoggedUser());
					dispatch(addAlert({text:"La cuenta ya ha sido activada", type:"error"}));
				}else{
					dispatch(addAlert({text:"Ha ocurrido un error. Intenta de nuevo", type:"error"}));
				}
			})
		}
		if(code.length>0 && error){
			setError(null);
		}
	},[code])

	const resendVerification = () => {
		axiosWithAuth().get("auth/resend_verification",{params:{method:activeTab}}).then(res=>{
			if(res.status === 200){
				dispatch(addAlert({text:"Nuevo correo de verificacion enviado.", type:"success"}));
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				setError("La cuenta ya esta verificada. Recarga la pagina");
			}
			dispatch(addAlert({text:"Ha occurido un error", type:"error"}));
		})
	}


	return (
		<div className="VerifyAccount">
			<div className="VerifyAccount-content">
				<Header title="Verifica tu cuenta" subtitle="Antes de continuar activar tu cuenta. Elige el metodo de verificacion que deseas usar e introduce el codigo de verificacion que recibiste en tu correo o en tu telefono."/>	

				<div className="VerifyAccount-tabs">
					{!validatedEmail && <button
						className={`tab ${activeTab === 'email' ? 'active' : ''}`}
						onClick={() => setActiveTab('email')}
						disabled={validatedEmail}
					>
						Email
					</button>}
					{!validatedPhone && <button
						className={`tab ${activeTab === 'sms' ? 'active' : ''}`}
						onClick={() => setActiveTab('sms')}
						disabled={validatedPhone}
					>
						SMS
					</button>}
				</div>

				<InputField
					name="code"
					label="Codigo de verificacion"
					value={code}
					setValue={setCode}
					error={error}
					maxLength={8}
				/>

				{loggedIn?
					<div className="VerifyAccount-tabContent">
						<Button action={resendVerification} className="sendCode">
							Enviar codigo por {activeTab === "email" ? "correo" : "SMS"}
						</Button>
					</div>
				:
					<Button
						className="disconnect"
						action={() => {
							navigate("/")
						}}
						variant="tertiary"
					>
						Inicia sesion para reenviar el codigo
					</Button>
				}

			</div>


			{loggedIn?
				<Button
					className="disconnect"
					action={() => {
						dispatch(logout())
						navigate("/")
					}}
					variant="tertiary"
				>
					Desconectar
				</Button>
			:
				<Button
					className="disconnect"
					action={() => {
						navigate("/")
					}}
					variant="tertiary"
				>
					Volver atras
				</Button>
			
			}
		</div>
	);
}

export default VerifyAccount;