import React, {useState,useEffect} from "react";
import Header from "components/Header";
import Button from "components/Button";
import {Table} from "components/Table";
import InputField from "components/InputField";
import "./Users.scss";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsers} from "Actions/UsersActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Pagination from "components/Pagination";
import {TableItem} from "components/Table";
import {useNavigate} from "react-router";
import { ReactComponent as EyeIcon } from "../../../assets/icons/visibilityIcon.svg";
import Modal from "components/Modal";
import {addAlert} from "Actions/SystemActions";
import DateRange from "components/DateRange/DateRange";
import Dropdown from "components/Dropdown";
import classNames from "classnames";
import {useSearchParams} from "react-router-dom";


function Users() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
	const [query, setQuery] = useState(searchParams.get("query") || "");
	const [showAdminActionsModal,setShowAdminActionsModal] = useState(false);
	const users = useSelector(store => store.UsersReducer).users;
	const [filtersExpanded,setFiltersExpanded] = useState(false);

	const [filters, setFilters] = useState({
        id: searchParams.get("id") || '',
        email: searchParams.get("email") || '',
        abbasoonCode: searchParams.get("abbasoonCode") || '',
        referrerId: searchParams.get("referrerId") || '',
        gender: searchParams.get("gender") || '',
        status: searchParams.get("status") || '',
        // countries: searchParams.get("countries") ? searchParams.get("countries").split(",") : [],
        startDate: searchParams.get("startDate") || '',
        endDate: searchParams.get("endDate") || '',
        age: searchParams.get("age") || ''
    });

	const pagination = useSelector(store => store.UsersReducer).pagination;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const fetchUsers = () => {
		const params = {
			query,
			page,
			...filters
		}

		const filteredParams = Object.keys(params)
        .filter(key => params[key] !== '' && params[key] !== null && params[key] !== undefined)
        .reduce((obj, key) => {
            obj[key] = params[key];
            return obj;
        }, {});

		const queryParams = new URLSearchParams(filteredParams);
        setSearchParams(queryParams);
		dispatch(getAllUsers(filteredParams));
	};

	useEffect(()=>{
		if (query.length > 3 || query.length === 0) {
			setPage(1);  // Reset page to 1
			fetchUsers();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[query]);

	useEffect(()=>{
		fetchUsers();
	},[page])

	const handleSearch = () => {
		setPage(1);
		fetchUsers();
	}

	return (
		<div className="Users">
			<Header title={"Afiliados"}/>
			<div className="wrapper">
				<div className="options">
					<div className="mainOptions">
						<InputField
							name="search"
							className="search"
							value={query}
							setValue={(nVal) => {setQuery(nVal)}}
						/>
						<Button variant="tertiary" action={()=>{setFiltersExpanded(!filtersExpanded)}}>
							{filtersExpanded?"Ocultar filtros":"Mostrar filtros"}
						</Button>
						<Button variant="tertiary" action={()=>{setShowAdminActionsModal(true)}}>
							Accions de admin
						</Button>
					</div>

					<div className={classNames("secondaryOptions",filtersExpanded?"expanded":"collapsed")}>
						<Filters filters={filters} setFilters={setFilters} handleSearch={handleSearch}/>
					</div>



				</div>
				<Table columns={[
					"","id","Correo electronico","Nombre completo","Codigo"
				]}>
					{users?.map(user=> {
						return <TableItem key={user.id}>
							<td
								onClick={()=>{navigate({pathname:"/admin/user/"+user.id})}}
							>
								<EyeIcon/>
							</td>
							<td>
								{user.id}
							</td>
							<td>
								{user.email}
							</td>
							<td>
								{user.fullName}
							</td>
							<td>
								{user.abbasoonCode}
							</td>
						</TableItem>
					}
				)}
				</Table>
				
				{pagination.lastPage > 1?
					<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
				:null}

			</div>
			
			{showAdminActionsModal?<Modal showCloseIcon closeModal={()=>{setShowAdminActionsModal(null)}} >
					<div className="modal-content adminActions">
						<AdminActions/>
					</div>
				</Modal>:null}

		</div>
	);
}

export default Users;

const AdminActions = () => {

	const dispatch = useDispatch();

	const resendEmails = () => {
		axiosWithAuth().get("users/admin/resend/verification",{
			params:{allUsers:true}
		}).then(res=>{
			if(res.status===200){
				if(res.data.count > 1){
					dispatch(addAlert({text:`Correos de verificacion enviados correctamente a ${res.data.count} usuarios`,type:"success"}))
				}else{
					dispatch(addAlert({text:`No hay ningun usuario pendiente de verificacion`}))
				}
			}
		}).catch(err=>{
			dispatch(addAlert({text:`Ha ocurrido un error`}));
		})
	}

	return (<>
		<Header title="Acciones generales" subtitle="Afectan a TODOS los usuarios"/>
		<div className="actions">
			<Button variant="primary" action={resendEmails}>Enviar correos de verificacion</Button>
		</div>
	</>)
}

const Filters = ({filters,setFilters,handleSearch}) => {


	return (
		<div className="filters">
			<div className="row gp-1">
				<div className="row">
					<InputField
						name="id"
						label="id"
						value={filters.id}
						type="number"
						min="1"
						setValue={(value) =>
							setFilters({ ...filters, id: value })
						}
					/>
				</div>
				<InputField
					name="id"
					label="Correo"
					value={filters.email}
					type="email"
					setValue={(value) =>
						setFilters({ ...filters, email: value })
					}
				/>
			</div>

			<div className="row gp-1">
				<InputField
					name="abbasoonCode"
					label="Codigo de afiliado"
					value={filters.abbasoonCode}
					setValue={(value) =>
						setFilters({ ...filters, abbasoonCode: value })
					}
				/>
				<InputField
					name="referrerId"
					label="Codigo de referido"
					value={filters.referrerId}
					setValue={(value) =>
						setFilters({ ...filters, referrerId: value })
					}
				/>
			</div>

			{/* <div className="row">
				<InputField
					name="countries"
					label="Paises (delimitador ,)"
					value={filters.countries.join(",")}
					setValue={(value) =>
						setFilters({ ...filters, countries: value.split(",") })
					}
				/>
			</div> */}

			<div className="row gp-2">
				<InputField
					name="age"
					label="Edad mayor a"
					value={filters.age}
					setValue={(value) => setFilters({ ...filters, age: value })}
				/>

				<DateRange
					dateRange={{
						sDate: filters.startDate,
						eDate: filters.endDate,
					}}
					setDateRange={({ eDate, sDate }) => {
						setFilters({
							...filters,
							startDate: sDate,
							endDate: eDate,
						});
					}}
				/>
			</div>

			<div className="row gp-2 -fw">
				<Dropdown title={genderText[filters.gender] || "Genero"}>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, gender: "" });
						}}
					>
						Cualquiera
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, gender: "F" });
						}}
					>
						Femenino
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, gender: "M" });
						}}
					>
						Masculino
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, gender: "X" });
						}}
					>
						Otro
					</Dropdown.Item>
				</Dropdown>

				<Dropdown title={statusText[filters.status] || "Estado"}>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, status: "" });
						}}
					>
						Cualquiera
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, status: "pending" });
						}}
					>
						Pendiente
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, status: "lite" });
						}}
					>
						Lite
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							setFilters({ ...filters, status: "active" });
						}}
					>
						Activado
					</Dropdown.Item>
				</Dropdown>
				
				<Button className="searchNow" variant="secondary" action={handleSearch}>
					Buscar
				</Button>
			</div>
		</div>
	);

}

const genderText = {
	F:"Femenino",
	M:"Masculino",
	X:"Otro"
}
const statusText = {
	pending:"Pendiente",
	active:"Activo",
	lite:"Lite"
}