import {addAlert} from "Actions/SystemActions";
import {WalletContext} from "Context/AdminDashboardContext";
import TransactionModal from "Modals/TransactionModal";
import {formatCurrency} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {copyText} from "Utils/copyText";
import {TransactionDispatcher} from "Views/Transactions";
import {CopyIcon} from "assets";
import Button from "components/Button";
import Card from "components/Card";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import {TransactionsList} from "components/Table/Common/Transactions";
import React, {useContext, useState} from "react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";

function WalletPanel() {
	const { wallets, selectedWallet, setSelectedWallet, recentTransactions } = useContext(WalletContext); // Access userInfo from UserInfoContext
	const [showHistoryModal,setShowHistoryModal] = useState(false);
	const [viewTransaction,setViewTransaction] = useState(null);
	const navigate = useNavigate();
	
	return (
		<Card className="Panel Panel-Wallet">
			<Card.Content>
				<div className="info">

					<div className="balance">
						<p className="title">
							Balance
						</p>
						<p className="value">{formatCurrency(selectedWallet?.balance || 0)}</p>
					</div>

					<div className="uuid"
						onClick={()=>{
							copyText(selectedWallet?.uuid)
						}}
					>
						<div className="type">
							{selectedWallet?.walletType}
						</div>
						<p className="value">
							{selectedWallet?.uuid}
							<CopyIcon/>
						</p>
					</div>
				</div>

				<div className="transactions">
					{selectedWallet?.uuid ? <>
					<p className="title">Transacciones recientes</p>
					<div className="content">
						<TransactionsList
							transactions={recentTransactions}
							selectedWallet={selectedWallet}
							setViewTransaction={setViewTransaction}
						/>
					</div>
					</>:<>
						<p>Este usuario aun no tiene una wallet para la organizacion selecciondada.
							<Button
								action={()=>{navigate("/admin/wallet/types")}}
								variant="tertiary"
							>
								Asignar ahora
							</Button>
						</p>
					</>
					}
				</div>
			</Card.Content>

			<Card.Controls>

				<Dropdown title={"Seleccione wallet"}>
					{
						wallets.map(w => <Dropdown.Item key={w.uuid}
							action={()=>{setSelectedWallet(w)}}
						>
							{w.walletType}
						</Dropdown.Item>)
					}
				</Dropdown>


				<Button
					xvariant="tertiary"
					action={()=>{setShowHistoryModal(true)}}
				>
					Ver todas las transacciones
				</Button>
			</Card.Controls>

			{showHistoryModal?
				<TransactionsModel
					closeModal={()=>{setShowHistoryModal(false)}}
				/>
			:null}

			{viewTransaction?
				<TransactionModal transaction={viewTransaction} setViewTransaction={setViewTransaction}/>
			:null}
		</Card>
	);
}

export default WalletPanel;


const TransactionsModel = ({closeModal}) => {

	const [transactions,setTransactions] = useState([]);
	const [viewTransaction,setViewTransaction] = useState(null);
	const [pagination,setPagination] = useState(null);
	const [page,setPage] = useState(1);
	const [query,setQuery] = useState(null);
	const [status,setStatus] = useState("completed");
	const { selectedWallet } = useContext(WalletContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(()=>{
		if(selectedWallet?.uuid){
			fetchTransactions()
		}
	},[selectedWallet,page,status]);


	const handleSearch = () => {
		fetchTransactions();
	}

	const fetchTransactions = () => {
		setTransactions([]); // Necessary to clean older trnasaction
	
		axiosWithAuth().get("/transactions/"+selectedWallet.uuid,{
			params:{
				page:page,
				status,
				query:query
		}}).then(res=>{
			setTransactions(res.data);
			setPagination(JSON.parse(res.headers["pagination"] || {}))
		}).catch(err=>{
			if(err.response.status === 404){
				setTransactions([]);				
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
				console.log(err);
			}
		})
	}

	const cancelTransaction = (transaction) => {
		axiosWithAuth().post("/users/admin/"+transaction.uuid+"/cancel/transaction",{transactionId:transaction.uuid}).then(res=>{
			if(res.status === 200){	
				fetchTransactions();
				setViewTransaction(null);
			}
		}).catch(err=>{
			if(err.response.status === 409){
				dispatch(addAlert({text:"La transaccion no se encuentra pendiente. Recarga la pagina", type:"error"}))
				fetchTransactions();
				setViewTransaction(null);
				// setTransactions()
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		})
	}


	return (<Modal 
		className="Modal-History Transactions"
		closeModal={closeModal}
		showCloseIcon
	>
		<Card>
			<Card.Title>
				<Header title="Transacciones"/>
				<Button variant="secondary" action={()=>{navigate("/send")}}>Crear transaccion</Button>
			</Card.Title>

			<Card.Content>
				<div className="top">
			
					<div className="controls row -fw">
						<div className="search-field adm">
							<InputField
								name="Buscar"
								value={query}
								setValue={(nVal) => {setQuery(nVal)}}
								slot={<Button variant="tertiary" className="search-field-btn" action={handleSearch}>
									Buscar
								</Button>}
							/>
						</div>
			
						<div className="statuses row gp-1">
							<Checkbox
								label="Completadas"
								value={status==="completed"}
								action={()=>{setStatus("completed")}}
							/>
							<Checkbox
								label="Pendientes"
								value={status==="pending"}
								action={()=>{setStatus("pending")}}
							/>
							<Checkbox
								label="Canceladas"
								value={status==="cancelled"}
								action={()=>{setStatus("cancelled")}}
							/>
						</div>
					</div>
			
				</div>

				<div className="transactionsWrapper">
					{transactions.length>0?			
						<TransactionDispatcher
							type="list"
							transactions={transactions}
							setViewTransaction={setViewTransaction}
							pagination={pagination}
							setPage={setPage}
							selectedWallet={selectedWallet}
						/>
					:<div className="notFound">
						<p>Esta wallet aun no tiene transacciones</p>
					</div>}
				</div>
		
				{viewTransaction?
					<TransactionModal transaction={viewTransaction} setViewTransaction={setViewTransaction} cancelTransaction={cancelTransaction} isAdmin/>
				:null}

			</Card.Content>
			<Card.Controls borderless>
				<Button variant="secondary" action={()=>{closeModal()}}>Cerrar</Button>
			</Card.Controls>
		</Card>
	</Modal>)
}