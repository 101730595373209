import React, {useState,useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editProduct, getAllProducts} from "Actions/ProductsActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {TableItem} from "components/Table";
import {Table} from "components/Table";
import Header from "components/Header";
import Button from "components/Button";
import InputField from "components/InputField";
import Modal from "components/Modal";
import Confirmation from "components/Confirmation";
import "./Products.scss";
import {addAlert} from "Actions/SystemActions";
import Dropdown from "components/Dropdown";
import {FileUpload} from "components";
import ProductBenefitsModal from "./ProductBenefitsModal";
import {formatCurrency} from "Utils/TextFormat";

function Products() {
	const [query,setQuery] = useState("");
	const [editableProduct,setEditableProduct] = useState(null);
	const [showAddConfirm,setShowAddConfirm] = useState(false);
	const [showEditConfirm,setShowEditConfirm] = useState(false);
	const [newProduct,setNewProduct] = useState(null);
	const {products,postStatus,response} = useSelector(store => store.ProductsReducer);
	const [apiKeys,setApiKeys] = useState([]);
	const [apiKeysProduct,setApiKeysProduct] = useState(null);
	const [showApiKeyConfirm,setShowApiKeyConfirm] = useState(false);	
	const [productItemBenefits,setProductItemBenefits] = useState(null);
	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(getAllProducts());
	},[]);

	useEffect(()=>{
		if(query.length>3){
			dispatch(getAllProducts(query));
		}else if(query.length===0){
			dispatch(getAllProducts());
		}
	},[query]);

	const updateProduct = () => {
		if(editableProduct){
			const capDesc = editableProduct["description"].charAt(0).toUpperCase() + editableProduct["description"].slice(1)
			dispatch(editProduct({
				...editableProduct,
				name: String(editableProduct["name"]).toUpperCase(),
				description: capDesc,
			}));
		}
	}

	const addProduct = () => {
		// Determine if this method is better than going through actions
		axiosWithAuth().post("/products/",newProduct).then(res=>{
			if(res.status===201){
				dispatch(getAllProducts());
				setNewProduct(null);
				dispatch(addAlert({text:"Producto añadido", type:"success"}))
			}
		}).catch(err=>{
			console.log(err);
		})
	}

	useEffect(()=>{
		if(response?.status === 200 && response?.config.url.split("/").includes("products") && response?.config.method === "patch"){
			dispatch(getAllProducts());
			setEditableProduct(null)
		}
	},[postStatus,response]);	

	const getApikeys =()=>{
		axiosWithAuth().get("/system/apikeys").then(res=>{
			if(res.status===200){
				setApiKeys(res.data);				
			}
		}).catch(err=>{
			console.log(err);
		})
	}
	useEffect(()=>{
		getApikeys();
	},[]);
	return (
		<div className="Products">
			<Header title={"Productos"}/>
			<div className="wrapper">
				<div className="options">
					<div className="search">
						<InputField
							name="search"
							value={query}
							setValue={(nVal) => {setQuery(nVal)}}
						/>
					</div>
					<Button variant="tertiary" action={()=>{setNewProduct({})}}>
						+ Añadir producto
					</Button>
				</div>
				<Table columns={{
					id:"id",
					name:"Nombre",
					description:"Descripcion",
					price:"Precio",
					null:null,
					null2:null,
					null3:null
				}}>
					{products.map(result=><TableItem key={result.id}>
					<td>{result.id}</td>
					<td>{result.name}</td>
					<td>{result.description}</td>
					<td>{formatCurrency(result.priceCents / 100)}</td>
					{/* TODO - Find a way to verify if user has been already assigned */}
					<td onClick={() => {setEditableProduct(result)}}>Editar</td>
					{/* TODO ----  */}
					{/* <td onClick={() => {setApiKeysProduct(result)}}>Origins</td> */}
					<td onClick={() => {setProductItemBenefits(result)}}>Benefits</td>
				</TableItem>)}

				</Table>
			</div>


			{editableProduct?
				<Modal showCloseIcon closeModal={()=>{setEditableProduct(null)}} >
					<div className="modal-content edit">
						<EditProduct editProduct={() => {setShowEditConfirm(true)}} editableProduct={editableProduct} setEditableProduct={setEditableProduct}/>
					</div>
				</Modal>
			:null}
			{apiKeysProduct?
				<Modal showCloseIcon closeModal={()=>{setApiKeysProduct(null)}} >
					<div className="modal-content edit">
						<ApiKeyProduct apiKeyProduct={() => {setShowApiKeyConfirm(true)}} apiKeysProduct={apiKeysProduct} setApiKeysProduct={setApiKeysProduct} apiKeys={apiKeys}/>
					</div>
				</Modal>
			:null}
			{newProduct?
				<Modal showCloseIcon closeModal={()=>{setNewProduct(null)}} >
					<div className="modal-content add">
						<AddProduct addProduct={() => {setShowAddConfirm(true)}} newProduct={newProduct} setNewproduct={setNewProduct}/>
					</div>
				</Modal>
			:null}

			{productItemBenefits ?
				<ProductBenefitsModal closeModal={()=>{setProductItemBenefits(null)}} productId={productItemBenefits.id}/>
			: null}
			
			{showAddConfirm?<Confirmation
				title="Agregar producto?"
				description={`Se agregara el siguiente producto\n${newProduct?.name}`}
				closeModal={()=>{
					setShowAddConfirm(false);
				}}
				accept={()=>{addProduct()}}
			/>:null}

			{showEditConfirm?<Confirmation
				title="Editar producto?"
				description={`Se Editara el siguiente producto\n${editableProduct?.name}`}
				closeModal={()=>{
					setShowEditConfirm(false);
					// setEditableProduct(null);
				}}
				accept={()=>{updateProduct()}}
			/>:null}			
		</div>
	);
}

export default Products;

const EditProduct = ({editableProduct,setEditableProduct,editProduct}) => {

	const setImage = (imageUrl) => {
		setEditableProduct({
			...editableProduct,
			imageUrl
		})
	}

	return (<>
		<Header title="Editar producto"/>
		<div className="edit-area">
			<InputField 
				label="id"
				value={editableProduct.id}
				readonly
			/>
			<InputField 
				label="Nombre"
				value={editableProduct.name}
				setValue={(nVal) => {setEditableProduct({...editableProduct, name:nVal})}}
			/>
			<InputField 
				label="Descripcion"
				value={editableProduct.description}
				setValue={(nVal) => {setEditableProduct({...editableProduct, description:nVal})}}
			/>
			<InputField 
				label="Precio (en centavos)"
				value={editableProduct.priceCents}
				setValue={(nVal) => {setEditableProduct({...editableProduct, priceCents:nVal})}}
				type="number"
			/>						
			<FileUpload.Image
				imageUrl={editableProduct.imageUrl}
				path={`products/${editableProduct.id}?lastUpdated=${Date.now()}`}
				onUpload={setImage}
			/>			

		</div>
		<div className="controls">
			<Button variant="secondary" action={()=>{setEditableProduct(null)}}>Cancelar</Button>
			<Button variant="primary" action={editProduct}>Actualizar</Button>
		</div>
	</>)
}

const AddProduct = ({newProduct,setNewproduct,addProduct}) => {

	return (<>
		<Header title="Editar producto"/>
		<div className="edit-area">
			<InputField 
				label="Nombre"
				value={newProduct.name || ""}
				setValue={(nVal) => {setNewproduct({...newProduct, name:nVal})}}
			/>
			<InputField 
				label="Descripcion "
				value={newProduct.description || ""}
				setValue={(nVal) => {setNewproduct({...newProduct, description:nVal})}}
			/>
			<InputField 
				label="Precio (en centavos)"
				value={newProduct.priceCents || ""}
				setValue={(nVal) => {setNewproduct({...newProduct, priceCents:nVal})}}
				type="number"
			/>
		</div>
		<div className="controls">
			<Button variant="secondary" action={()=>{setNewproduct(null)}}>Cancelar</Button>
			<Button variant="primary" action={addProduct} disabled={!(newProduct.name && newProduct.priceCents && newProduct.description)}>Agregar</Button>
		</div>
	</>)
}

const ApiKeyProduct = ({apiKeysProduct,setApiKeysProduct,apiKeys}) => {
	const [addedOrigins,setAddedOrigins] = useState({});
	const [delOrigins,setDelOrigins] = useState([]);
	
	const getOrigins =()=>{
		axiosWithAuth().get("/products/origins?query=productId:"+apiKeysProduct.id).then(res=>{
			if(res.status===200){
				setAddedOrigins(res.data.map(productOrigin => (
					{
						id: productOrigin.id,
						originId: productOrigin.originId,
						origin: productOrigin.origin,
						productId: productOrigin.productId			
					}
			)));
			}
		}).catch(err=>{
			console.log(err);
		})
	}

	const handleAddProductOrigins = (apiKey) => {
		setAddedOrigins({
			...addedOrigins,
			[apiKey.id]:{
				id: 0,
				origin: apiKey.origin,
				originId: apiKey.id,
				productId: 	apiKeysProduct.id
			}
		})
	}
	const handleRemove = (id) => {
		const newAddedOrigins = addedOrigins;
		if (newAddedOrigins[id]) {
			const delOrigin = newAddedOrigins[id];
			if (delOrigin.id > 0) {
				delOrigins.push(delOrigin.id);
				setDelOrigins(delOrigins);
			}
		}
		delete newAddedOrigins[id];
		setAddedOrigins({ ...newAddedOrigins });
	}

	const handleCreate = () => {
		const productOriginAdd = Object.values(addedOrigins).map(p => {
			if(p.id===0){
				return {productId:p.productId, originId:p.originId };
			}else{
				return null;
			}
		});	

		if(productOriginAdd?.length>0){
			axiosWithAuth().post("/products/origins",{productsOrigins:productOriginAdd}).then(res=>{
				if(res.status===200){
					setApiKeysProduct(null);
				}
			}).catch(err=>{
				console.log(err);
			})
		}

		if(delOrigins?.length>0){
			axiosWithAuth().patch("/products/origins",{productsOrigins:delOrigins}).then(res=>{
				if(res.status===200){
					setApiKeysProduct(null);
				}
			}).catch(err=>{
				console.log(err);
			})
		}

		if(productOriginAdd?.length===0 && delOrigins?.length===0){
			setApiKeysProduct(null);
		}										
	}

	useEffect(()=>{
		if(apiKeysProduct.id){
			getOrigins();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[apiKeysProduct]);

	return (<>
		<Header title="Publicar en tiendas"/>
		<div className="edit-area">
			<InputField 
				label="id"
				value={apiKeysProduct.id}
				readonly
			/>				
			<Dropdown title={"Origins"}>
				{apiKeys.map(apikey=>{
					if(!Object.keys(addedOrigins).includes(""+apikey.id)){
						return <Dropdown.Item key={apikey.id} action={()=>{handleAddProductOrigins(apikey)}}>
						<p>{apikey.origin}</p>
						</Dropdown.Item>
					}
				})}
			</Dropdown>			
		</div>
		<div className="content">
		{Object.values(addedOrigins).map(p => <div className="addedProduct">
						<InputField
							className="name"
							value={p.origin}
							readOnly
							slot={<Button action={()=>{handleRemove(p.originId)}}>
								Remove
							</Button>}	
						/>
					</div>)}
		</div>
		
		<div className="controls">
			<Button variant="secondary" action={()=>{setApiKeysProduct(null)}}>Cancelar</Button>
			<Button variant="primary" action={handleCreate}>Actualizar</Button>
		</div>
	</>)
}