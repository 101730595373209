import React, { useState, useEffect } from "react";
import Card from "components/Card";
import Modal from "components/Modal";
import Button from "components/Button";
import { axiosWithAuth } from "Utils/axiosWithAuth";
import InputField from "components/InputField";
import Dropdown from "components/Dropdown";
import {Table} from "components/Table";
import {TableItem} from "components/Table";

function ProductBenefitModal({ closeModal, productId }) {
	const [benefitTypes, setBenefitTypes] = useState([]);
	const [productBenefits, setProductBenefits] = useState([]);
	const addProductBenefit = (productId, benefitId) => {
		axiosWithAuth().post(`products/${productId}/benefits`,{
			benefitId
		}).then(res=>{
			fetchProductBenefits();
		}).catch(err=>{
			console.log(err);
		})
	}

	const fetchBenefitTypes = () => {
		axiosWithAuth().get(`benefits/types`).then(res=>{
			if(res.status===200){
				setBenefitTypes(res.data);
			}
		}).catch(err=>{
			console.log(err);
		})
	}

	const fetchProductBenefits = () => {
		axiosWithAuth().get(`products/${productId}/benefits`).then(res=>{
			if(res.status===200){
				setProductBenefits(res.data);
			}
		}).catch(err=>{
			console.log(err);
		})
	}

	const deleteProductBenefit = (productId, benefitId) => {
		axiosWithAuth().delete(`products/${productId}/benefits/${benefitId}`).then(res=>{
			if(res.status===200){
				fetchProductBenefits();
			}
		}).catch(err=>{
			console.log(err);
		})
	}

    useEffect(() => {
		fetchBenefitTypes();
    }, []);

    useEffect(()=>{
		if(productId){
			fetchProductBenefits();
		}
	},[productId]);

    return (
        <Modal className="edit" showCloseIcon closeModal={closeModal}>
            <Card>
                <Card.Title>
                    Benefits - {productId}
                </Card.Title>
                <Card.Content>                   
					<Table
						columns={{
							"id":"ID",
							"name":"Nombre",
							"add":"Agregar"
						}}
					>
						{benefitTypes.map(benefitType => <TableItem key={benefitType.id} className="benefit">
							<td>{benefitType.id}</td>
							<td>{benefitType.name}</td>
							<td>
								{productBenefits.findIndex(benefit => benefit.benefitId === benefitType.id)===-1
									?<Button variant="primary" action={()=>{addProductBenefit(productId,benefitType.id)}}>Agregar</Button>
									:<Button variant="secondary" action={()=>{deleteProductBenefit(productId,benefitType.id)}}>Eliminar</Button>}
							</td>
						</TableItem>)}
					</Table>

                </Card.Content>
                <Card.Controls borderless>
                        <Button variant="secondary" action={closeModal}>Cerrar</Button>
                </Card.Controls>
            </Card>
        </Modal>
    );
}

export default ProductBenefitModal;