import { axiosWithAuth } from "Utils/axiosWithAuth";
import {addAlert} from "./SystemActions";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const clearErrors = () => {
	return (dispatch) => dispatch({ type:CLEAR_ERRORS })
}

export const LOGIN_START =  "LOGIN_START";
export const LOGIN_SUCCESS =  "LOGIN_SUCCESS";
export const LOGIN_FAILURE =  "LOGIN_FAIL";

export const login = (data) => {
	return (dispatch) => {
		dispatch({ type:LOGIN_START });
		axiosWithAuth().post("auth/login", data).then(res=>{
			dispatch({ type:LOGIN_SUCCESS, payload:{data:res.data, response:res}});
			checkOnBoardingActions(res.data.userActions, res.data.status !== "active");
		}).catch(err => {
			if(err.message){
				dispatch({ type:LOGIN_FAILURE, payload: {error:err.message}});
			}
			if(err.response){
				if(err.response?.status === 404){
					// We return response bc we dont need to as we handle this case
					dispatch({ type: LOGIN_FAILURE, payload:{error:{code:404,path:"login",message:"No existe ningun usuario con ese correo"}, response:null} });
				}else if(err.response?.status === 401){
					dispatch({ type: LOGIN_FAILURE, payload:{error:{code:401,path:"login",message:"La contraseña es incorrecta"}, response:null} });
				}else{
					dispatch({ type: LOGIN_FAILURE, payload:{error:err.response.data, response:err.response} });
				}

			}
		})
	}
}

const checkOnBoardingActions = (actions, accountVerified) => {
	if(!accountVerified && !actions.map(a => a.name).includes("onboarding.completed")){
		window.location.replace("/onboarding")
	}
}

export const SESSION_EXPIRED =  "SESSION_EXPIRED";
export const sessionExpired = () => {
	return (dispatch) => {
		dispatch({ type:LOGOUT });
		dispatch(addAlert({text:"Tu sesion ha expirado"}))
	}
}	

export const LOGOUT =  "LOGOUT";

export const logout = () => {
	return (dispatch) => {
		axiosWithAuth().post("auth/logout").then(res=>{
			dispatch({ type:LOGOUT });
		})
	}
}	

export const REGISTER_START =  "REGISTER_START";
export const REGISTER_SUCCESS =  "REGISTER_SUCCESS";
export const REGISTER_FAILURE =  "REGISTER_FAILURE";

export const register = (data) => {
	return (dispatch) => {
		dispatch({ type:REGISTER_START });
		axiosWithAuth().post("auth/register", data).then(res=>{
			dispatch(addAlert({text:"Cuenta creada satisfactoriamente.\nInicia sesion con los detalles que has introducido.", type:"success"}))

			dispatch({ type: REGISTER_SUCCESS, payload:{response:res}});
		}).catch(err=>{
			if(err.message){
				dispatch({ type: REGISTER_FAILURE, payload:{error:err.message}});
			}
			if(err.response){
				dispatch({ type: REGISTER_FAILURE, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}

export const FETCH_LOGGED_USER_START =  "FETCH_LOGGED_USER_START";
export const FETCH_LOGGED_USER_SUCCESS =  "FETCH_LOGGED_USER_SUCCESS";
export const FETCH_LOGGED_USER_FAIL =  "FETCH_LOGGED_USER_FAIL";

export const fetchLoggedUser = () => {
	return (dispatch) => {
		dispatch({ type:FETCH_LOGGED_USER_START });
		axiosWithAuth().get("users").then(res=>{
			dispatch({ type: FETCH_LOGGED_USER_SUCCESS, payload:{response:res, data:res.data}});
		}).catch(err=>{
			if(err.message){
				dispatch({ type: FETCH_LOGGED_USER_FAIL, payload:{error:err.message}});
			}
			if(err.response){
				dispatch({ type: FETCH_LOGGED_USER_FAIL, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}

export const FETCH_USER_WALLETS_START =  "FETCH_USER_WALLETS_START";
export const FETCH_USER_WALLETS_SUCCESS =  "FETCH_USER_WALLETS_SUCCESS";
export const FETCH_USER_WALLETS_FAILURE =  "FETCH_USER_WALLETS_FAIL";

export const fetchLoggedUserWallets = () => {
	return (dispatch,getState) => {

		const selectedOrganizationId = getState().SystemReducer.selectedOrganization.id;

		dispatch({ type:FETCH_USER_WALLETS_START });
		axiosWithAuth().get("users/wallets",{
			params:{
				organizationId: selectedOrganizationId
			}
		}).then(res=>{
			dispatch({ type: FETCH_USER_WALLETS_SUCCESS, payload:{response:res, data:res.data}});
		}).catch(err=>{
			if(err.message){
				dispatch({ type: FETCH_USER_WALLETS_FAILURE, payload:{error:err.message}});
			}
			if(err.response){
				dispatch({ type: FETCH_USER_WALLETS_FAILURE, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}

export const SELECT_WALLET =  "SELECT_WALLET";

export const selectWallet = (walletId) => {
	return (dispatch) => {
		dispatch({ type:SELECT_WALLET, payload:walletId });
	}
}

export const FETCH_SHARES_START =  "FETCH_SHARES_START";
export const FETCH_SHARES_SUCCESS =  "FETCH_SHARES_SUCCESS";
export const FETCH_SHARES_FAILURE =  "FETCH_SHARES_FAILURE";

export const fetchShares = () => {
	return (dispatch,getState) => {
		dispatch({ type:FETCH_SHARES_START });
		const selectedOrganizationId = getState().SystemReducer.selectedOrganization.id;
		axiosWithAuth().get(`/users/shares`,{
			params:{
				organizationId: selectedOrganizationId

			}}
		).then(res=>{
				dispatch({ type:FETCH_SHARES_SUCCESS, payload:{response:res, data:res.data} });
			}).catch(err=>{
				if(err.message){
					dispatch({ type: FETCH_LOGGED_USER_FAIL, payload:{error:err.message}});
				}
				if(err.response){
					dispatch({ type: FETCH_LOGGED_USER_FAIL, payload:{error:err.response.data, response:err.response} });
				}
			})
	}
}

export const FETCH_USER_PERMISSION =  "FETCH_USER_PERMISSION";
export const fetchUserPermissions = () => {
	return (dispatch) => {
		axiosWithAuth().get("/permissions/user").then(res=>{
			if(res.data.length > 0){
				dispatch({
					type: FETCH_USER_PERMISSION,
					payload: res.data.filter(p => p.assigned === true)
				})
			}
		}).catch(err => {
			if(err.response && err.response.status === 404){
				dispatch({
				  type: FETCH_USER_PERMISSION,
				  payload: [],
				});
			}else{
				// TODO
				console.error('An error occurred:', err);
			}
		})
		
		;
	}
}