import {addAlert} from "Actions/SystemActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Button from "components/Button";
import Confirmation from "components/Confirmation";
import Header from "components/Header";
import Modal from "components/Modal";
import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import "./EditPhoneModal.scss";
import InputField from "components/InputField";

function EditPhoneModal({existingPhone,closeModal,onSuccess}) {


	const [phone,setPhone] = useState(null);
	const [showVerify,setShowVerify] = useState(false);
	const dispatch = useDispatch();

	const updatePhone = () => {
		axiosWithAuth().patch("users/phone",{
			phone: `+${phone}`
		}).then(res=>{
			if(res.status === 200){
				dispatch(addAlert({text:"Se ha enviado el codigo de verificacion", type:"success"}));
				onSuccess(`+${phone}`);
				closeModal();
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				dispatch(addAlert({text:"Ese telefono ya esta en uso", type:"error"}));
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error.", type:"error"}));
			}
		})
	}

	console.log(existingPhone, `+${phone}`);

	return <Modal className="editPhoneModal">
		<div className="card p-1">
			<Header title="Editar telefono"/>

			<InputField.PhoneInput
				phone={phone}
				setPhone={(nVal)=>{setPhone(nVal)}}
			/>
			{existingPhone === `+${phone}` && <p className="warning-existing-phone">
				Este numero de telefono ya esta en uso.
			</p>}

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={()=>{setShowVerify(true)}}
					disabled={existingPhone === `+${phone}`}
				>Aceptar</Button>
			</div>
		</div>

		{showVerify && <Confirmation
			title={"Deseas cambiar tu numero de telefono?"}
			description={"Si cambias tu numero de telefono, dejaras de recibir mensajes de texto hasta que confirmes tu nuevo numero."}
			accept={()=>{
				setShowVerify(false);
				updatePhone();
			}}
			closeModal={()=>{setShowVerify(false)}}
		></Confirmation>}

	</Modal>
}

export default EditPhoneModal;

export function VerifyPhoneModal ({closeModal, onSuccess}){
	const [code,setCode] = useState("");
	const dispatch = useDispatch();

	useEffect(()=>{
		if(code.length === 8){
			validateCode();
		}
	},[code])
	
	const validateCode = () => {
		axiosWithAuth().post("users/validate/phone",{
			code
		}).then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:"Tu telefono ha sido validado", type:"success"}));
				onSuccess();
				closeModal();
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				dispatch(addAlert({text:"Tu telefono ha sido validado", type:"success"}));
				onSuccess();
				closeModal();
			}else{
				console.log(err)
				dispatch(addAlert({text:"Ha ocurrido un error.", type:"error"}));
			}
		})
	}

	const requestNewCode = () => {
		axiosWithAuth().get("users/validate/phone").then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:"Se ha enviado un nuevo codigo de verificacion", type:"success"}));
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				dispatch(addAlert({text:"Parece que este telefono ya fue confirmado. Por favor recarga la pagina", type:"error"}));
			}else{
				console.log(err)
				dispatch(addAlert({text:"Ha ocurrido un error.", type:"error"}));
			}
		})
	}

	return <Modal className="editPhoneModal">
		<div className="card p-1">
			<Header title="Verificar telefono"/>
			<div className="input">
				<InputField
					label="Codigo de verificacion"
					value={code}
					setValue={setCode}
					maxLength={8}
				/>
			</div>


			<div className="row gp-1 actions">
				<Button
					variant="tertiary"
					action={requestNewCode}
				>Reenviar codigo</Button>
				<Button
					variant="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={validateCode}
					disabled={code.length !== 8}
				>Verificar codigo</Button>
			</div>
		</div>

	</Modal>
}