import {UserInfoContext} from "Context/AdminDashboardContext";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {AffiliatesTree} from "Views/Affiliates";
import Button from "components/Button";
import Card from "components/Card";
import Modal from "components/Modal";
import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";



function ReferrersPanel() {
	
	const [showReferrers,setShowReferrers] = useState(false);
	const [totalReferrers,setTotalreferrers] = useState(false)
	const { userId } = useParams();

	useEffect(()=>{
		axiosWithAuth().get(`/users/admin/${userId}/affiliates`).then(res => {
			setTotalreferrers((JSON.parse(res.headers["pagination"]))?.total)
		})
	},[])
	
	return (
		<Card className="Panel Panel-Referrers">
			
			<Card.Title>
				Directos
			</Card.Title>

			<Card.Content>
				<p className="total">
					{totalReferrers}
				</p>
			</Card.Content>

			<Card.Controls
				borderless
			>

				<Button
					variant="tertiary"
					action={()=>{setShowReferrers(true)}}
				>
					Ver referidos
				</Button>
			</Card.Controls>

			{showReferrers?
				<ReferrersModal
					closeModal={()=>{setShowReferrers(false)}}
				/>
			:null}

		</Card>
	);
}

export default ReferrersPanel;


const ReferrersModal = ({closeModal}) => {
	
	const { userInfo } = useContext(UserInfoContext);

	return <Modal
		className="Modal Referrers"
		closeModal={closeModal}
		showCloseIcon
	>
		<Card>
			<Card.Title>
				Afiliados
			</Card.Title>
			<Card.Content>
				<AffiliatesTree
					userData={userInfo}
					isAdmin
				/>
			</Card.Content>
			<Card.Controls borderless>
				<Button variant="secondary" action={closeModal}>Cerrar</Button>
			</Card.Controls>
		</Card>
	</Modal>
}