import React, {useEffect, useState} from "react";
import "./Onboarding.scss";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useMultistepForm} from "Utils/hooks";
import Button from "components/Button";
import InputField from "components/InputField";
import {formatDate} from "Utils/TextFormat";
import Card from "components/Card";
import Dropdown from "components/Dropdown";
import Modal from "components/Modal";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";
import Checkbox from "components/Checkbox";
import EditPhoneModal, {VerifyPhoneModal} from "Modals/EditPhoneModal";
import {useNavigate} from "react-router";


function Onboarding() {
	
	const userData = useSelector(store=>store.UserReducer.data);
	const [newData,setNewData] = useState();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(()=>{
		if(userData?.uuid){
			setNewData({...userData});
		}

		if(userData?.uuid && userData?.userActions.map(a => a.name).includes("onboarding.completed")){
			navigate("/");
		}
	},[userData]);

	const updateData = (dataFields) => {
		setNewData({...newData, ...dataFields})
	}

	const {
		prevStep,
		isFirstStep,
		nextStep,
		isLastStep,
		step,
		currentStepIndex
	} = useMultistepForm([
		{
			content: <WelcomeSlide/>
		},
		{
			title: "Detalles Personales",
			content: <UserdetailsForm {...newData} updateData={updateData}/>
		},
		{
			title: "Informacion de contacto",
			content: <ContactdetailsForm {...newData} updateData={updateData}/>
		},
		{
			title: "Personalizacion de codigo de afiliado",
			content: <AffiliateCodeForm {...newData} updateData={updateData}/>
		},
		{
			title: "Seguridad",
			content: <PasswordForm {...newData} updateData={updateData} email={userData.email}/>
		},
	])

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosWithAuth().post("users/onboard", {
			fullName: newData.fullName,
			city: newData.city,
			state: newData.state,
			country: newData.country,
			zipCode: newData.zipCode,
			dob: newData.dob,
			gender: newData.gender,
			password: newData.password
		}).then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:"Proceso finalizado de manera exitosa", type:"success"}));
				navigate("/");
			}
		}).catch(err => {
			if(err.response?.status === 409){
				dispatch(addAlert({text:"Proceso finalizado de manera exitosa", type:"success"}));
				navigate("/");
			}else{
				console.log(err.response);
				dispatch(addAlert({text:"Se ha producido un error", type:"error"}));
			}
		})
	}

	const checkFields = () => {
		if(isLastStep && !newData.password){
			return true;
		}
		if(newData?.password?.length < 8){
			return true
		}
		return false
	}

	return (
		<div className="Onboarding">

			<div className="content">
				<form className="card" onSubmit={handleSubmit}>
					{step.title && <div className="card-title">
						{step.title}
					</div>}
					
					
					{step.content && <div className="card-body">
						{step.content}
					</div>}

					<div className="card-controls">
						<Button
							action={prevStep}
							disabled={isFirstStep}
							>
							Anterior
						</Button>

						<Button
							action={nextStep}
							type={isLastStep?"submit":"button"}
							disabled={checkFields()}
						>
							{isLastStep?"Finalizar":"Siguiente"}
						</Button>
					</div>
				</form>
			</div>

		</div>
	);
}

export default Onboarding;


const Slide = ({children,className}) => {
	return <div className={classNames("Slide",className)}>
		{children}
	</div>
}

const WelcomeSlide = ({}) => {
	return (
		<Slide className={"welcome"}>
			<h1>Bienvenido</h1>
			<p>Antes de continuar, nos gustaria confirmar algunos detalles contigo.</p>
		</Slide>
	)
}

const UserdetailsForm = ({
	fullName,
	email,
	dob,
	city,
	state,
	country,
	gender,
	updateData
}) => {

	const onChangeHandler = (e) => {
		updateData({[e.target.name]: e.target.value});
	}

	return (
		<Slide>
			<InputField
				label={"Nombre completo"}
				value={fullName}
				name="fullName"
				onChange={onChangeHandler}
			/>
			<InputField
				label={"Correo Electronico"}
				value={email}
				name="email"
				readOnly
			/>
			<InputField
				label={"Ciudad"}
				value={city}
				name="city"
				onChange={onChangeHandler}
			/>
			<InputField
				label={"Estado o Provincia"}
				value={state}
				name="state"
				onChange={onChangeHandler}
			/>
			<InputField
				label={"Pais"}
				value={country}
				name="country"
				onChange={onChangeHandler}
			/>
			<InputField
				label={"Fecha de nacimiento"}
				value={formatDate(dob, "isoDateOnly")}
				name="dob"
				onChange={onChangeHandler}
				type={"date"}
			/>
				<Dropdown title={
					gender==="M"?"Masculino":
					gender==="F"?"Femenino":
					gender==="X"?"Otro"
					:"Sexo"
				} variant="secondary">
					<Dropdown.Item
						action={() => {
							updateData({gender: 'M'})
						}}
						selected={gender==='M'}
					>
						Masculino
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							updateData({gender: 'F'})
						}}
						selected={gender==='F'}
					>
						Femenino
					</Dropdown.Item>
					<Dropdown.Item
						action={() => {
							updateData({gender: 'X'})
						}}
						selected={gender==='X'}
					>
						Otro
					</Dropdown.Item>
				</Dropdown>
		</Slide>
	)
}

const ContactdetailsForm = ({
	phone,
	validatedPhone,
	email,
	updateData
}) => {

	const [showModal,setShowModal] = useState(false);
	const [showModalVerification,setShowModalVerification] = useState(false);

	return (
		<Slide>
			<InputField
				label={"Correo electronico"}
				value={email}
				name="email"
				readOnly
			/>
			<InputField
				autofocus
				label={"Numero de telefono"}
				value={phone}
				name="fullName"
				readOnly
				slot={
					<Button
					action={() => {setShowModal(true)}}
				>
					Editar
				</Button>
				}
			/>


			<div className="row gp-1 justify -between">
				<Checkbox
					label="Verificado"
					value={validatedPhone}
					readOnly
				/>

				{validatedPhone===false &&<Button
					variant="primary"
					action={()=>{
						setShowModalVerification(true)
					}}
					>
					Verificar
				</Button>}
			</div>
		

			{showModal && <EditPhoneModal 
				existingPhone={phone} 
				onSuccess = {(newPhone) => {
					updateData({phone:newPhone})
				}}
				closeModal={()=>{
					setShowModal(false)
				}}
			/>}

			{showModalVerification && <VerifyPhoneModal
				onSuccess = {(successObj) => {
					updateData({validatedPhone:true})
				}}
				closeModal={()=>{
					setShowModalVerification(false)
				}}
			/>}

		</Slide>
	)
}

const AffiliateCodeForm = ({
	referrerId,
	abbasoonCode,
	userActions,
	updateData
}) => {
	const [newCode,setNewCode] = useState(abbasoonCode);
	const [showModal,setShowModal] = useState(false);
	const [verificationError,setVerificationError] = useState(null);
	const dispatch = useDispatch();

	const updateCode = () => {

		axiosWithAuth().post("/users/onboard/abbasoonCode",{code:newCode}).then(res=>{
			if(res.status === 201){
				dispatch(addAlert({text:"Tu codigo ha sido actualizado de manera exitosa"}));
				setVerificationError(null);
				updateData({
					abbasoonCode: res.data.abbasoonCode,
					userActions: [
						...userActions, res.data.actions
					]
				})
			}else if(res.status === 200){
				setVerificationError(null);
				dispatch(addAlert({text:"Tu codigo ya ha sido actualizado. Si aun ves tu codigo anterior puedes refrescar la pagina"}));
				updateData({
					abbasoonCode: newCode,
					userActions: [
						...userActions, ...res.data.actions
					]
				})
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				setVerificationError("Ese codigo ya esa en uso");
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		})
	}


	return (
		<Slide>
			<InputField
				label={"Codigo de referido"}
				value={referrerId}
				name="abbasoonCode"
				readOnly
			/>
			<InputField
				autofocus
				label={"Codigo de afiliado"}
				value={abbasoonCode}
				name="abbasoonCode"
				readOnly
				slot={!userActions.map(e=>e.name).includes("onboarding.codeUpdated") && <Button
					action={() => {setShowModal(true)}}
				>
					Editar
				</Button>}
			/>

			<p>
				Este es el momento de personalizar tu codigo de afiliado. Este codigo sera tu identificador unico en la plataforma.<br/>Con este codigo podras referir a tus amigos y familiares para que se unan a la plataforma.
			</p>


			{showModal && <Modal className="editABcode card p-1"
				closeModal={()=>{setShowModal(false)}}
					showCloseIcon
			>
				<InputField
					autofocus
					label={"Codigo de afiliado"}
					value={newCode}
					error={verificationError}
					setValue={setNewCode}
					name="abbasoonCode"
				/>

				<div className="info">
					Asegurate que el codigo de afiliado que selecciones sea el correcto.<br/>Una vez que se haya completado el proceso de registro, no podras cambiar tu codigo de afiliado. De lo contrario, deberas contactar con soporte tecnico.
				</div>

				<div className="actions">
					<Button
						variant="secondary"
						action={()=>{setShowModal(false)}}
					>
						Cancelar
					</Button>
					<Button
						disabled={newCode === abbasoonCode || userActions.map(e=>e.name).includes("onboarding.codeUpdated")}
						action={updateCode}
					>
						Verificar y guardar
					</Button>
				</div>

			</Modal>}

		</Slide>
	)
}

const PasswordForm = ({
	password="",
	email,
	updateData
}) => {

	const [showPassword,setShowPassword] = useState(false);
	const onChangeHandler = (e) => {
		updateData({password: e.target.value});
	}

	const verifyPassword = {
		length: () => password.length >= 8,
		email: () => !password.includes(email.split('@')[0])
	}

	return (
		<Slide className={"security"}>
			<InputField
				label={"Nueva contraseña"}
				value={password}
				onChange={onChangeHandler}
				type={showPassword?"text":"password"}
				name="password"
				minLength="8"
				required
			/>
			<div className="passwordReqs">
				<ul>
					{/* <li>
						<p className={classNames("length", {'success': verifyPassword.email()})}>
							No puede ser igual a tu correo electronico
						</p>
					</li> */}
					<li>
						<p className={classNames("length", {'success': verifyPassword.length()})}>
							Almenos 8 caracteres
						</p>
					</li>
				</ul>
			</div>
			<Checkbox
				label="Mostrar contraseña"
				value={showPassword}
				toggleValue={setShowPassword}
			/>
		</Slide>
	)
}
