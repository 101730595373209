import { axiosWithAuth } from "Utils/axiosWithAuth";
import {addAlert} from "./SystemActions";

export const FETCH_PRODUCTS_START =  "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS =  "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE =  "FETCH_PRODUCTS_FAILURE";

export const getAllProducts = (query) => {
	return (dispatch) => {
		dispatch({ type:FETCH_PRODUCTS_START });
		axiosWithAuth().get("/products/",{
			params:{query}
		}).then(res=>{
			dispatch({ type:FETCH_PRODUCTS_SUCCESS, payload:{data:res.data?.products || [], response:res}});
		}).catch(err => {
			if(err.message){
				dispatch({ type:FETCH_PRODUCTS_FAILURE, payload: {error:err.message}});
			}
			if(err.response){
				dispatch({ type: FETCH_PRODUCTS_FAILURE, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}

export const EDIT_PRODUCTS_START =  "EDIT_PRODUCTS_START";
export const EDIT_PRODUCTS_SUCCESS =  "EDIT_PRODUCTS_SUCCESS";
export const EDIT_PRODUCTS_FAILURE =  "EDIT_PRODUCTS_FAILURE";

export const editProduct = (product) => {
	return (dispatch) => {
		dispatch({ type:EDIT_PRODUCTS_START });
		axiosWithAuth().patch("/products/"+product.id,product).then(res=>{
			dispatch({ type:EDIT_PRODUCTS_SUCCESS, payload:{data:res.data, response:res}});
			dispatch(addAlert({text:"Producto editado correctamente", type:"success"}))
		}).catch(err => {
			if(err.message){
				dispatch({ type:EDIT_PRODUCTS_FAILURE, payload: {error:err.message}});
			}
			if(err.response){
				dispatch({ type: EDIT_PRODUCTS_FAILURE, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}
