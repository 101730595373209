import {getAllProducts} from "Actions/ProductsActions";
import {addAlert} from "Actions/SystemActions";
import {OrdersContext} from "Context/AdminDashboardContext";
import {AdminDashboardProvider} from "Context/AdminDashboardContext";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Button from "components/Button";
import Card from "components/Card";
import Checkbox from "components/Checkbox";
import Confirmation from "components/Confirmation";
import Dropdown from "components/Dropdown";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import {OrdersTable} from "components/Table";
import {ProductsTable} from "components/Table";
import Textarea from "components/Textarea";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

function OrdersPanel() {
	const [showHistory,setShowHistory] = useState(false);

	return (<>
		<Card 
			className="Panel Panel-Shares"
			centered
			action={()=>{setShowHistory(true)}}
		>
				Ordenes de compra
				
		</Card>
		{/* Using fragment bc otherwise child clicks will propagate to card onclick */}
		{showHistory?
			<OrdersModal
				closeModal={()=>{setShowHistory(false)}}
			/>
		:null}
	</>
		
	);
}

export default OrdersPanel;

const OrdersModal = ({closeModal}) => {	

	// Todo - create view order as admin
	const {userId} = useParams();
	const [products,setProducts] = useState([]);
	const [orders,setOrders] = useState([]);
	const [pagination, setPagination] = useState({});
	const [page, setPage] = useState(1);
	const [showProductsTotal,setShowProductsTotal] = useState(false);
	const [showDeleteConfrm,setShowDeleteConfirm] = useState(null);
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const [showAssignPModal,setShowAssignPModal] = useState(false);
	const dispatch = useDispatch();

	useEffect(()=>{
		if(userId){
			fetchUserTotalProducts();
			fetchUserOrders();
		}
	},[userId,page,selectedOrganization.id]);

	const addOrder = (orderId,description,orderProducts) => {
		axiosWithAuth().post("users/products",{userId, orderId, products:orderProducts, description}).then(res=>{
			if(res.status==201){
				fetchUserTotalProducts(userId);
				setShowAssignPModal(false);
				dispatch(addAlert({text:`Nueva orden creada con exito`, type:"success"}));
				fetchUserOrders();
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				dispatch(addAlert({text:"Ya existe una orden con ese id", type:"error"}));
			}else{
				console.log(err);
				dispatch(addAlert({text:"Ha ocurrido un error"}))
			}
		})
	}

	const fetchUserTotalProducts = () => {
		axiosWithAuth().get("/users/"+userId+"/products").then(res=>{
			setProducts(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setProducts([]);
			}
		})
	}
const fetchUserOrders = () => {
	axiosWithAuth().get("/users/admin/"+userId+"/orders",{
		params:{
			organizationId: selectedOrganization?.id,
			page
		}
	}).then(res=>{
		setPagination(JSON.parse(res.headers["pagination"] || {}))
		setOrders(res.data);
	}).catch(err=>{
		if(err.response.status === 404){
			setOrders([]);
		}
	})
}

	const deleteHistory = (orderId) => {
		// TODO - convert into delete  and move it into the modal
		// if(orderId){
		// 	axiosWithAuth().delete(`users/admin/${userId}/products`,{
		// 		data:{orderId}
		// 	}).then(res=>{
		// 		if(res.status===201){
		// 			fetchUserProducts(userId);
		// 			dispatch(addAlert({text:`Orden eliminada`, type:"success"}))
		// 		}else{
		// 			dispatch(addAlert({text:`Parece que algo salio mal. Intenta mas tarde`}))
		// 		}
		// 	}).catch(err=>{
		// 		dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}))
		// 	})
		// }
	}

	const renderTable = () => {

		if(showProductsTotal && products?.length > 0){
			return <ProductsTable
				products={products}
			/>
		}else{
			if(orders?.length > 0){
				return <OrdersTable
					orders={orders}
					pagination={pagination}
					setPage={setPage}
				/>
			}else{
				return <div>
					<p>Este usuario aun no tiene ordenes
						<br/>
						<Button variant="tertiary" action={()=>{setShowAssignPModal(true)}}>Agregar ordene de compra</Button>
					</p>
				</div>
			}
		}
	}


	return (<Modal
		className="Modal-History Orders"
		closeModal={closeModal}
		showCloseIcon
	>
		<Card>

			<Card.Title>
				<Header title={showProductsTotal?"Ordenes de compra":"Productos"}/>
			</Card.Title>

			<Card.Content>
				<div className="row gp-1 options">
					<Button variant="secondary" action={()=>{setShowAssignPModal(true)}}>Nueva orden de compra</Button>
				
					<div className="checkboxgroup">
						{products.length>0?
							<Checkbox
								label="Mostrar total productos"
								value={showProductsTotal}
								toggleValue={setShowProductsTotal}
							/>:null
						}
					</div>
				</div>

				{renderTable()}

			</Card.Content>
			
			<Card.Controls borderless>
				<Button variant="secondary" action={closeModal}>Cancelar</Button>
				<Button variant="primary" action={()=>{setShowAssignPModal(true)}}>Agregar orden</Button>
			</Card.Controls>
		</Card>

		{showAssignPModal?
			<AddOrderModal addOrder={addOrder} setShowAssignPModal={setShowAssignPModal} userProducts={products}/>
		:null}

		{showDeleteConfrm?
			<Confirmation
				title="Eliminar orden?"
				description="El usuario no podra ver esta transaccion en su historial ni en el total de sus productos."
				accept={()=>{deleteHistory(showDeleteConfrm.orderId)}}
				closeModal={()=>{setShowDeleteConfirm(null)}}
			/>
		:null}
	</Modal>)
}

const AddOrderModal = ({setShowAssignPModal,addOrder,closeModal}) => {

	const [description,setDescription] = useState(null);
	const [showConfirm,setShowConfirm] = useState(false);
	const [orderId,setOrderId] = useState(" ");
	const products = useSelector(store=>store.ProductsReducer.products);
	
	const [addedProducts,setAddedProducts] = useState({});
	
	const dispatch = useDispatch();
	
	useEffect(()=>{
		dispatch(getAllProducts());
	},[])


	const handleQuantity = (e) => {
		setAddedProducts({
			...addedProducts,
			[e.target.name]:{
				...addedProducts[e.target.name],
				quantity: Number(e.target.value)
			}
		})
	}

	const handleAddProduct = (product) => {
		setAddedProducts({
			...addedProducts,
			[product.id]:{
				id: product.id,
				name: product.name,
				quantity: 1,
			}
		})
	}

	const handleRemoveProduct = (id) => {
		const newAddedProducts = addedProducts;
		delete newAddedProducts[id];
		setAddedProducts({...newAddedProducts});
	}

	const handleAdd = () => {
		addOrder(
			orderId,
			description,
			Object.values(addedProducts)
		)
	}

	return (<Modal
		className="addProduct"
		closeModal={closeModal}
		showCloseIcon
	>
		<Card>
			<Card.Content>
				<Header title="Añadir orden"/>
				<div className="add-area">
					{/* Product selection */}
					<Dropdown title={"Productos"}>
						{products.map(product=>{
							if(!Object.keys(addedProducts).includes(""+product.id)){
								return <Dropdown.Item key={product.uuid} action={()=>{handleAddProduct(product)}}>
									<p>{product.name}</p>
								</Dropdown.Item>
							}
						})}
					</Dropdown>

					{Object.keys(addedProducts).length > 0?<p className="info">
						Productos a agregar
					</p>:<p className="info">
						Elige productos de la lista	
					</p>}
					<div className="addedProducts">
						{/* Show added products */}
						
						<div className="content">
							{Object.values(addedProducts).map(p => <div className="addedProduct">
								<InputField
									className="name"
									value={p.name}
									readOnly
								/>
								<InputField
									className="quantity"
									value={addedProducts[p.id].quantity}
									name={p.id}
									onChange={handleQuantity}
									type="number"
									min={1}
									slot={<Button action={()=>{handleRemoveProduct(p.id)}}>
										Remove
									</Button>}

								/>
							</div>)}
						</div>
					</div>
					
					<InputField
						label="Order id"
						value={orderId}
						setValue={(nVal)=>{
							setOrderId(nVal)
						}}
					/>
					<Textarea 
						label="Descripcion"
						value={description}
						setValue={setDescription}
						maxLength={500}
					/>
				</div>


				<Card.Controls>
					<Button variant="secondary" action={()=>{setShowAssignPModal(null)}}>Cancelar</Button>
					<Button variant="primary"
						action={()=>{setShowConfirm(true)}} 
						disabled={!orderId || !description || Object.keys(addedProducts).length<=0}
					>
						Agregar
					</Button>
				</Card.Controls>

				{showConfirm?
					<Confirmation
						title="Asignar producto?"
						description={`Por favor revisa los detalles de la orden`}
						closeModal={()=>{setShowConfirm(false)}}
						accept={()=>{
							handleAdd()
						}}
					/>
				:null}
			</Card.Content>
		</Card>
	</Modal>
	)
}