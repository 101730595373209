import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	FETCH_LOGGED_USER_START,
	FETCH_LOGGED_USER_SUCCESS,
	FETCH_LOGGED_USER_FAIL,
	REGISTER_START,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
	FETCH_USER_WALLETS_START,
	FETCH_USER_WALLETS_SUCCESS,
	FETCH_USER_WALLETS_FAILURE,
	SELECT_WALLET,
	LOGOUT,
	CLEAR_ERRORS,
	FETCH_SHARES_FAILURE,
	FETCH_SHARES_START,
	FETCH_SHARES_SUCCESS,
	FETCH_USER_PERMISSION
} from "../Actions/UserActions.js"

const initialState = {
	data:{},
	userWallets:[],
	userShares:[],
	selectedWallet:{},
	loggedIn:false,
	initialLoad:true,
	error:null,
	getStatus:null,
	postStatus:null,
	response: null,
}

const UserReducer = (state=initialState,action) => {
	switch(action.type){

		case CLEAR_ERRORS:
			return {...state, error:null}

		case FETCH_LOGGED_USER_START:
			return {...state, getStatus:true, initialLoad:true};
		case FETCH_LOGGED_USER_SUCCESS:
			return {...state, isLoading:false, data:action.payload.data, loggedIn:true, error:null, response:action.payload.response, initialLoad:false};
		case FETCH_LOGGED_USER_FAIL:
			return {...state, isLoading:false, response:action.payload.response, error:action.payload.error,initialLoad:false};

		case LOGIN_START:
			return { ...state, getStatus:true, loggedIn:false, error:null, response: null, data:{}};
		case LOGIN_SUCCESS:
			return { ...state, getStatus:false, data:action.payload.data, loggedIn:true, error:null, response:action.payload.response};
		case LOGIN_FAILURE:
			return { ...state, getStatus:false, response:action.payload.response, error:action.payload.error}

		case LOGOUT:
			return {...state, getStatus:false, data:{}, loggedIn:false, error:null, response:null};

		case REGISTER_START:
			return {...state, postStatus: true, loggedIn: false, error: null, response: null};
		case REGISTER_SUCCESS:
			return {...state, postStatus: false, loggedIn: false, error: null, response: action.payload.response};
		case REGISTER_FAILURE:
			return {...state, postStatus: false, response: action.payload.response, error: action.payload.error}

		case FETCH_LOGGED_USER_START:
			return { ...state, getStatus:true, loggedIn:false, error:null, response: null, data:{}};
		case FETCH_LOGGED_USER_SUCCESS:
			return { ...state, getStatus:false, data:action.payload.data, loggedIn:true, error:null, response:action.payload.response};
		case FETCH_LOGGED_USER_FAIL:
			return { ...state, getStatus:false, response:action.payload.response, error:action.payload.error}

		case FETCH_USER_WALLETS_START:
			return { ...state, getStatus:true, error:null, response: null, userWallets:[]};
		case FETCH_USER_WALLETS_SUCCESS:
			const wallets = action.payload.data;
			const selectedWallet = wallets[0] || {}
			return { ...state, getStatus:false, userWallets:action.payload.data, selectedWallet, error:null, response:action.payload.response};
		case FETCH_USER_WALLETS_FAILURE:
			return { ...state, getStatus:false, wallets:[], selectedWallet:{}, response:action.payload.response, error:action.payload.error}

		case SELECT_WALLET:
			const wallet = state.userWallets.filter(k => k.uuid === action.payload)[0];
			return {...state, selectedWallet:wallet}

	

		case FETCH_SHARES_START:
			return { ...state, getStatus:true, error:null, response: null, userShares:[]};
		case FETCH_SHARES_SUCCESS:
			return { ...state, getStatus:false, userShares:action.payload.data, error:null, response:action.payload.response};
		case FETCH_SHARES_FAILURE:
			return { ...state, getStatus:false, response:action.payload.response, error:action.payload.error}

		case FETCH_USER_PERMISSION:
			return { ...state, permissions: action.payload}


		default:
			return state;
	}
}

export default UserReducer;