import React,{useEffect,useState} from "react";
import Header from "components/Header";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "components/Table";
import {getAllWalletTypes} from "Actions/WalletsActions";
import InputField from "components/InputField";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import {TableItem} from "components/Table";
import "./Wallet.scss";
import Confirmation from "components/Confirmation";
import {addAlert} from "Actions/SystemActions";
import Checkbox from "components/Checkbox";
import Card from "components/Card";


function Wallet() {

	const [showAddTypeModal,setShowAddTypeModal] = useState(false);
	const [showAssignModal,setShowAssignModal] = useState(false);
	const [showEditModal,setShowEditModal] = useState(null);
	const WalletTypes = useSelector(store=>store.WalletReducer).walletTypes;
	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(getAllWalletTypes());
		// eslint-disable-next-line
	},[]);

	const addType = (walletType) => {
		// Todo - check if this fits better in actions
		axiosWithAuth().post("wallet/create/type",walletType).then(res=>{
			dispatch(getAllWalletTypes());
			dispatch(addAlert({text:`Wallet ${walletType.name} creada`, type:"success"}))
			setShowAddTypeModal(false);
		});
	}

	const assignWallet = (userId,walletType) => {
		axiosWithAuth().post(`users/admin/${userId}/assign/wallet`,{userId,walletType}).then(res=>{
			if(res.status===201){
				setShowAssignModal(false);
				if(userId === "all"){
					dispatch(addAlert({text:`Wallet asignada a todos los usuarios`, type:"success"}))
				}else{
					dispatch(addAlert({text:`Wallet asignada al usuario ${userId}`, type:"success"}))
				}
			};
		});
	}

	return (
		<div className="Wallet">
			<Header title={"Tipos de wallets"} />
			<div className="wrapper">
				<div className="options">

					<Button variant="secondary" action={()=>{setShowAddTypeModal(true)}}>
						+ Añadir nuevo tipo de wallet
					</Button>
					<Button variant="tertiary" action={()=>{setShowAssignModal(true)}}>
						Asignar wallet a usuario
					</Button>
				</div>
				<Table columns={["id","nombre","divisa",""]}>

	

				{WalletTypes.map(type => (
					<TableItem key={type.id}>
						<td>{type.id}</td>
						<td>{type.name}</td>
						<td>{type.currency}</td>
						<td>
							<Button
								action={() => {
									setShowEditModal(type)
								}}
							>
								Editar
							</Button>
						</td>

					</TableItem>
				))}

				</Table>
			</div>

			{showEditModal?
				<EditModal type={showEditModal} updataData={getAllWalletTypes} closeModal={()=>{setShowEditModal(false)}}/>
			:null}

			{showAddTypeModal?
				<Modal showCloseIcon closeModal={()=>{setShowAddTypeModal(false)}} >
					<div className="modal-content search">
						<AddType addType={addType} closeModal={()=>{setShowAddTypeModal(false)}}/>
					</div>
				</Modal>
			:null}
			{showAssignModal?
				<Modal showCloseIcon closeModal={()=>{setShowAssignModal(false)}} >
					<div className="modal-content assign">
						<AssignWallet assignWallet={assignWallet} closeModal={()=>{setShowAssignModal(false)}} WalletTypes={WalletTypes}/>
					</div>
				</Modal>
			:null}
		</div>
	);
}

export default Wallet;


const EditModal = ({closeModal,type,updataData}) => {
	
	const [showVerify,setShowVerify] = useState(false);
	const [targetOrganization,setTargetOrganization] = useState();
	const [newDescription, setNewDescription] = useState(type.description)
	const {organizations} = useSelector(store => store.SystemReducer);
	const dispatch = useDispatch();

	useEffect(()=>{
		if(type.organizationId){
			setTargetOrganization(organizations[type.organizationId])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[type?.organizationId]);

	const aditWalletType = () => {
		axiosWithAuth().patch(`wallet/${type.id}/type`,{ description:newDescription,organizationId:targetOrganization.id}).then(res=>{
			if(res.status===200){				
				dispatch(addAlert({text:`Tipo de wallet modificada correcamente`, type:"success"}))
				setShowVerify(false);
				closeModal();
				dispatch(updataData());
			}
		}).catch(err=>{
			console.log(err);
			dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}))

		})
	}

	const handleEdit = () => {
		if(!targetOrganization || !targetOrganization.id){
			dispatch(addAlert({text:"Debes seleccionar una organizacion"}));
		}else{
			setShowVerify(true);
		}
	}

	return <Modal className="editWalleType">
		<Card>
			<Card.Title>
				Editar wallet
			</Card.Title>

			<Card.Content>
				<div className="info">
					<InputField
						label="Nombre"
						value={type.name}
						readOnly
					/>
					<InputField
						label="Moneda"
						value={type.currency}
						readOnly
					/>
					<InputField
						label="Fecha de creacion"
						value={type.createdAt}
						readOnly
					/>

					<Dropdown title={targetOrganization?.name || "Organizacion destino"}>
						<Dropdown.Item
							action={()=>{setTargetOrganization(null)}}
						>
							Ninguna
						</Dropdown.Item>
						{Object.values(organizations).map(org => <Dropdown.Item key={org.id}
							action={()=>{setTargetOrganization(org)}}
						>
							{org.name}
						</Dropdown.Item>)}
					</Dropdown>

					<Textarea
						label="Descripcion"
						value={newDescription}
						setValue={setNewDescription}
					/>
				</div>


			</Card.Content>

			<Card.Controls>
				<Button
					action={closeModal}
					variant="secondary"
				>
					Cancelar
				</Button>
				<Button
					action={()=>{handleEdit()}}
				>
					Guardar
				</Button>
			</Card.Controls>

		</Card>

		{showVerify?
			<Confirmation
				title="Crear nueva wallet"
				description={`Esta seguro que desea actualizar la wallet ${type.name}?`}
				closeModal={()=>{setShowVerify(false)}}
				accept={()=>{
					aditWalletType()
				}}
			/>
		:null}


	</Modal>

}

const AddType = ({addType,closeModal}) => {

	const [name,setName] = useState("");
	const [description,setDescription] = useState("");	
	const [currency,setCurrency] = useState("");
	const [targetOrganization,setTargetOrganization] = useState();
	const [type,setType] = useState();
	const [showConfirm,setShowConfirm] = useState(false);
	const organizations = useSelector(store => store.SystemReducer).organizations;
	const dispatch = useDispatch();

	const handleAdd = () => {
		if(!targetOrganization || !targetOrganization.id){
			dispatch(addAlert({text:"Debes seleccionar una organizacion"}));
		}else if(!name){
			dispatch(addAlert({text:"Debes de ingresar un nombre"}));
		}else if(!description){
			dispatch(addAlert({text:"Debes ingresar una descripción."}));
		}else{
			setShowConfirm(true);
		}		
	}

	return (<>
		<Header title="Añadir tipo de wallet"/>
		<div className="form-area">
			<InputField 
				label="Nombre"
				value={name}
				setValue={setName}
				maxLength={45}
			/>
			<InputField 
				label="Divisa"
				value={currency}
				setValue={setCurrency}
				maxLength={45}
			/>
			<Dropdown title={targetOrganization?.name || "Organizacion destino"}>
				<Dropdown.Item
					action={()=>{setTargetOrganization(null)}}
				>
					Ninguna
				</Dropdown.Item>
				{Object.values(organizations).map(org => <Dropdown.Item key={org.id}
					action={()=>{setTargetOrganization(org)}}
				>
					{org.name}
				</Dropdown.Item>)}
			</Dropdown>
			<InputField 
				label="Type"
				value={type}
				setValue={setType}
				maxLength={45}
			/>
			<Textarea 
				label="Descripcion"
				value={description}
				setValue={setDescription}
				maxLength={500}
			/>
		</div>
		<div className="controls">
			<Button variant="secondary" action={()=>{closeModal()}}>Cancelar</Button>
			<Button variant="primary" action={handleAdd}>Añadir</Button>
		</div>

		{showConfirm?
			<Confirmation
				title="Crear nueva wallet"
				description={`Desea un nuevo tipo de wallet llamado "${name}"?`}
				closeModal={()=>{setShowConfirm(false)}}
				accept={()=>{addType({name,description,currency,organizationId:targetOrganization.id,type})}}
			/>
		:null}
	</>)
}

const AssignWallet = ({assignWallet,WalletTypes}) => {

	const [query,setQuery] = useState("");
	const [results,setResults] = useState([]);
	const [userData,setUserData] = useState(null);
	const [selectedWalletType,setSelectedWalletType] = useState(null);
	const [phase,setPhase] = useState(1);
	const [showConfirm,setShowConfirm] = useState(false);
	const [allUsers,setAllUsers] = useState(false);

	useEffect(()=>{
		if(allUsers){
			setUserData({id:"all"})
		}else{
			setUserData(null)
		}
	},[allUsers])

	useEffect(()=>{
		if(query.length > 3){
			axiosWithAuth().get("users/admin/?query="+query).then(res=>{
				setResults(res.data);
			})
		}
	},[query]);


	if(phase===2){
		return (<>
			<Header title="Asignar wallet a usuario" subtitle="Selecciona la wallet a asignar"/>
			<div className="wallet-select">
				<Dropdown variant="secondary" title={selectedWalletType?selectedWalletType.name:"Mostrar columnas"}>
					{WalletTypes.map((type,index)=>{
						return <Dropdown.Item key={index} action={()=>{setSelectedWalletType(type)}}>
							{type.name}
						</Dropdown.Item>
					})}
				</Dropdown>

			</div>
			<div className="controls">
				<Button variant="secondary" action={()=>{setPhase(1)}}>
					Atras
				</Button>
				<Button action={()=>{setShowConfirm(true)}} disabled={!selectedWalletType}>
					Asignar
				</Button>
			</div>

			{showConfirm?
				<Confirmation
					title="Crear nueva wallet"
					description={`Desea asignar al usuario ${userData?.fullName}\nEl tipo de wallet "${selectedWalletType?.name}"?`}
					closeModal={()=>{setShowConfirm(false)}}
					accept={()=>{assignWallet(userData.id,selectedWalletType.id)}}
				/>
			:null}


		</>)
	}else{
		return (<>
			<Header title="Asignar wallet a usuario" subtitle="Selecciona el usuario"/>
			<div className="search-area">
				<Checkbox
					label="Asignar a todos los usuarios"
					className="allUsers"
					value={allUsers}
					toggleValue={setAllUsers}
				/>

				{!allUsers?<>
					<InputField 
						value={query}
						setValue={setQuery}
						maxLength={256}
					/>

					<Table columns={{"id":"id",
						"fullName":"Nombre",
						"email":"Correo Electronico",
						"abbasoonCode":"codigo"}} className="query-results">
						{results.map(result=><TableItem 
							key={result.id} 
							className={userData?.id === result.id?"selected":""}
							action={()=>{setUserData(result)}}
						>
							<td>{result.id}</td>
							<td>{result.fullName}</td>
							<td>{result.email}</td>
							<td>{result.abbasoonCode}</td>
						</TableItem>)}
					</Table>
				</>:null}
			</div>
			<div className="controls">
				<Button action={()=>{setPhase(2)}} disabled={!userData?.id}>
					Siguiente
				</Button>
			</div>
		</>)
	}
}